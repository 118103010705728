import React, { useState, useEffect, useRef } from 'react';
import Loader from '../Loader/Loader';
import Breadcrumb from '../Layouts/Breadcrumb';
import NoDataFoundTableRow from '../Partials/NoDataFoundTableRow';
import { convertSeconds, date_format, getStatusBadge, get_google_map_link, handleShowAddress, is_empty_object } from '../../helpers/helpers';
import { get, post } from '../../helpers/api';
import { toast } from 'react-toastify';
import { FormField } from '../../helpers/FormField';
import ReportsFilters from './Partials/ReportsFilters';

function TrackerModal() {
    const [loading, setLoading] = useState(false);
    const [filterModelName, setFilterModelName] = useState('');
    const ref = useRef(true);
    const [reportData, setReportData] = useState([]);
    const [reportStatistics, setReportStatistics] = useState([]);

    const handleOnFilteredData = (data) => {
        setReportData(data?.data);
        setReportStatistics(data?.counts);
    }

    const handleOnFilteredDataFetchedLoading = (data) => {
        setLoading(data);
    }

    return (
        <div className="container-fluid mt-4">
            <Breadcrumb links={[
                { path: '/', text: 'Home' },
                { text: 'Tracker Model' },
            ]} />

            <div className='row mb-2'>
                <div className='col-12'>
                    <h2>Tracker Model</h2>
                </div>
            </div>
            <ReportsFilters
                report_type={'tracker-model'}
                onFilteredDataChange={handleOnFilteredData}
                onFilteredDataFetchedLoading={handleOnFilteredDataFetchedLoading}
                showDeviceFiter={false}
                showDateFilter={false}
                showModelFilter={true}
            />

            {reportData.length > 0 && (
                <div className='row mt-3 mb-3'>
                    {reportStatistics.map((data, key) => (
                        <div className='col-2' key={key}>
                            <div className='p-3 bg-light d-flex justify-content-between'>
                                <span className='fw-bold'>{data?.status_name}</span>
                                <span className='fw-bold'>{data?.count}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className='row mb-2'>
                <div className='col-12'>
                    <div className='table-responsive mt-4'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">IMEI</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Vehicle Plate</th>
                                    <th scope="col">SIM</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    reportData.length > 0 ? (
                                        reportData.map((data, key) => (
                                            <tr key={key}>
                                                <td>{data?.imei}</td>
                                                <td>{data?.customer_name}</td>
                                                <td>{data?.number_plate}</td>
                                                <td>{data?.tracker_sim}</td>
                                                <td dangerouslySetInnerHTML={{ __html: getStatusBadge(data?.status === 'Active' ? 'Occupied' : data?.status) }}></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <NoDataFoundTableRow />
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TrackerModal;

