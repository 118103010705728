import React, { useState, useEffect } from 'react';
import { get, remove } from '../../helpers/api';
import Loader from '../Loader/Loader';
import Breadcrumb from '../Layouts/Breadcrumb';
import NoDataFoundTableRow from '../Partials/NoDataFoundTableRow';
import ModelNameForm from './ModelNameForm';
import { toast } from 'react-toastify';
import { hasPermission } from '../../helpers/helpers';

function ModelNamesList() {
    const [showModelNameForm, setShowModelNameForm] = useState(false);
    const [selectedModelNameID, setSelectedModelNameID] = useState(null);

    const [modelNames, setModelNames] = useState([]);
    const [loading, setLoading] = useState(true);

    // Handle close model name form
    const handleCloseModelNameForm = () => {
        setShowModelNameForm(false);
        setSelectedModelNameID(null);
    };

    // handle add/show model name form
    const handleAddModelNameForm = () => {
        setShowModelNameForm(true);
        setSelectedModelNameID(null);
    };

    // handle edit button click
    const handleEdit = (modelNameID) => {
        setShowModelNameForm(true);
        setSelectedModelNameID(modelNameID);
    };

    // handle delete model name
    const handleDelete = async (modelNameID) => {
        try {
            if (!(hasPermission('Delete Model Name'))) {
                toast.error('You are not authorized to perform this action');
                return;
            }
            if (window.confirm('Are you sure you want to delete this model name?')) {
                const res = await remove(`/api/model-names/delete/${modelNameID}`);
                if (res?.code === 200) {
                    toast.success(res?.data?.message);
                    fetchModelNames();
                }
                else {
                    toast.error(res?.message);
                }
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const fetchModelNames = async () => {
        try {
            const res = await get(`/api/model-names`);
            if (res.code === 200)
                setModelNames(res.data);
            else
                toast.error(res.message);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // fetch list of model names when page loaded/rendered
    useEffect(() => {
        if (!(hasPermission('List Model Names'))) {
            toast.error('You are not authorized to perform this action');
            return;
        } else
            fetchModelNames();
    }, []);

    return (
        <div className="container-fluid mt-4">

            {loading ? (
                <Loader />
            ) : (
                <>
                    <Breadcrumb links={[
                        { path: '/', text: 'Home' },
                        { text: 'Model Names' },
                    ]} />
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <h2>Model Names</h2>
                        </div>
                        {hasPermission('Add Model Name') && (
                            <div className='col-6 text-end'>
                                <button ctype="button"
                                    className="btn btn-sm btn-secondary"
                                    id=''
                                    data-bs-toggle="modal"
                                    data-bs-target="#modelNameModal"
                                    onClick={handleAddModelNameForm}>
                                    Add Model Name
                                </button>
                            </div>
                        )}
                    </div>

                    <ModelNameForm
                        show={showModelNameForm}
                        modelNameID={selectedModelNameID}
                        onClose={handleCloseModelNameForm}
                        fetchModelNames={fetchModelNames}
                    />

                    <div className='table-responsive'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        Model Name
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modelNames.length > 0 ? (
                                    modelNames.map((modelName, index) => (
                                        <tr key={index}>
                                            <td>{modelName?.model_name}</td>
                                            <td className='action-column'>

                                                {(hasPermission('Update Model Name') || hasPermission('Delete Model Name')) && (
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-sm bg-secondary text-white dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            Actions
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {hasPermission('Update Model Name') && (
                                                                <button
                                                                    type="button"
                                                                    className="dropdown-item"
                                                                    key={index}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modelNameModal"
                                                                    onClick={() => handleEdit(modelName?.id)}

                                                                >Edit
                                                                </button>
                                                            )}

                                                            {hasPermission('Delete Model Name') && (
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => handleDelete(modelName?.id)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <NoDataFoundTableRow />
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )
            }
        </div >
    );
}

export default ModelNamesList;
