import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useRouteTracker = () => {
    const location = useLocation();

    useEffect(() => {
        // Get all toasts
        const toasts = document.querySelectorAll('.Toastify__toast');
    
        toasts.forEach(toast => {
            // Get the route indicator within the toast
            const routeIndicator = toast.querySelector('.route-indicator');
    
            if (routeIndicator) {
                // Get the route associated with the toast
                const toastRoute = routeIndicator.textContent;
    
                if (location.pathname === toastRoute) {
                    // If the current route matches the toast's route, show the toast
                    toast.style.display = '';
                } else {
                    // If the current route does not match the toast's route, hide the toast
                    toast.style.display = 'none';
                }
            }
        });
    }, [location]);
    
};
