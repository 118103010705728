import React from 'react';

const NotFound = () => {
    return (
        <div className="container-fluid mt-5 text-center">
            <h1 className="display-4">404</h1>
            <p className="lead">Page Not Found</p>
            <p className="lead">Sorry, the page you are looking for might be in another castle.</p>
        </div>
    );
};

export default NotFound;
