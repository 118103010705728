import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ links }) => {
  return (
    <nav aria-label="breadcrumb" className='p-2 bg-light mb-3'>
      <ol className="breadcrumb p-0 m-0" style={{ fontSize: '0.8rem!important' }}>
        {links.map((link, index) => (
          <li key={index} className={`breadcrumb-item${index === links.length - 1 ? ' active' : ''}`}>
            {index === links.length - 1 ? (
              <span className="text-muted">{link.text}</span>
            ) : (
              <Link to={link.path}>{link.text}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
