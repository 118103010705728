import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/api';
import Loader from '../Loader/Loader'; // Import your Loader component
import Breadcrumb from '../Layouts/Breadcrumb';
import NoDataFoundTableRow from '../Partials/NoDataFoundTableRow';
import { toast } from 'react-toastify';

function CompanyPaymentReport() {
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState([]);
    const fetCompaniesReportData = async () => {
        setLoading(true);
        try {
            const res = await get(`/api/reports/payments`);
            if (res.code === 200) {
                setReportData(res.data);
            } else {
                toast.error(res.message)
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetCompaniesReportData();
    }, []);



    return (
        <div className="container-fluid mt-4">
            <Breadcrumb links={[
                { path: '/', text: 'Home' },
                { text: 'Payments Report' },
            ]} />

            <div className='row mb-2'>
                <div className='col-3'>
                    <h2>Payments Report</h2>
                </div>
            </div>

            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className='table-responsive mt-4'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Company</th>
                                    <th scope="col">Total Amount</th>
                                    <th scope="col">Paid</th>
                                    <th scope="col">Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.length > 0 ? (
                                    reportData.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row?.company_name || ''}</td>
                                            <td>{row?.total_amount}</td>
                                            <td>{row?.total_paid}</td>
                                            <td>{row?.total_remaining}</td>
                                        </tr>
                                    ))

                                ) : (
                                    <NoDataFoundTableRow />
                                )}

                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default CompanyPaymentReport;
