import moment from 'moment-timezone';
import React, { useRef, useState } from "react";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";


function DateFilter({ handleDateRangeFilter, placement = 'auto' }) {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedValue, setSelectedValue] = useState('today');
    const [selectedRange, setSelectedRange] = useState(null);

    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    const yesterdayStart = new Date();
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);
    const yesterdayEnd = new Date();
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
    yesterdayEnd.setHours(23, 59, 59, 999);

    const lastTwoDaysStart = new Date();
    lastTwoDaysStart.setDate(lastTwoDaysStart.getDate() - 1);
    lastTwoDaysStart.setHours(0, 0, 0, 0);

    const last7DaysStart = new Date();
    last7DaysStart.setDate(last7DaysStart.getDate() - 6);
    last7DaysStart.setHours(0, 0, 0, 0);

    const last30DaysStart = new Date();
    last30DaysStart.setDate(last30DaysStart.getDate() - 29);
    last30DaysStart.setHours(0, 0, 0, 0);

    const thisMonthStart = new Date();
    thisMonthStart.setDate(1);
    thisMonthStart.setHours(0, 0, 0, 0);
    const thisMonthEnd = new Date();
    thisMonthEnd.setMonth(thisMonthEnd.getMonth() + 1, 0);
    thisMonthEnd.setHours(23, 59, 59, 999);

    const lastMonthStart = new Date();
    lastMonthStart.setMonth(lastMonthStart.getMonth() - 1, 1);
    lastMonthStart.setHours(0, 0, 0, 0);
    const lastMonthEnd = new Date();
    lastMonthEnd.setDate(0);
    lastMonthEnd.setHours(23, 59, 59, 999);

    const last6MonthsStart = new Date();
    last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 6, 1);
    last6MonthsStart.setHours(0, 0, 0, 0);

    const thisYearStart = new Date();
    thisYearStart.setMonth(0, 1);
    thisYearStart.setHours(0, 0, 0, 0);
    const thisYearEnd = new Date();
    thisYearEnd.setMonth(11, 31);
    thisYearEnd.setHours(23, 59, 59, 999);

    // Set the start date of last year
    const lastYearStart = new Date();
    lastYearStart.setFullYear(lastYearStart.getFullYear() - 1);
    lastYearStart.setMonth(0, 1);
    lastYearStart.setHours(0, 0, 0, 0);

    // Set the end date of last year
    const lastYearEnd = new Date();
    lastYearEnd.setFullYear(lastYearEnd.getFullYear() - 1);
    lastYearEnd.setMonth(11, 31);
    lastYearEnd.setHours(23, 59, 59, 999);

    const dateRanges = {
        today: [todayStart, todayEnd],
        yesterday: [yesterdayStart, yesterdayEnd],
        last_2_days: [lastTwoDaysStart, todayEnd],
        last_7_days: [last7DaysStart, todayEnd],
        last_30_days: [last30DaysStart, todayEnd],
        this_month: [thisMonthStart, thisMonthEnd],
        last_month: [lastMonthStart, lastMonthEnd],
        last_6_month: [last6MonthsStart, todayEnd],
        this_year: [thisYearStart, thisYearEnd],
        last_year: [lastYearStart, lastYearEnd],
    };

    const handleSelect = (event) => {
        const value = event.target.value;
        setSelectedValue(value);

        if (value === 'custom') {
            setShowDatePicker(true);
            // Reset the selected range to today's date when 'Custom' is selected
            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);
            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 999);
            setSelectedRange([todayStart, todayEnd]);
        } else {
            setShowDatePicker(false);
            handleDateRangeFilter(value);
            const range = dateRanges[value];
            setSelectedRange(range);
        }
    };


    const handleDateRangeChange = (value) => {
        if (!value) {
            // Set the selected range to today's date when the cancel button is clicked
            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);
            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 999);
            value = [todayStart, todayEnd];
            setSelectedRange(value);
            handleDateRangeFilter('today');
            return;
        }

        const label = Object.keys(dateRanges).find(key =>
            dateRanges[key][0].getTime() === value[0].getTime() &&
            dateRanges[key][1].getTime() === value[1].getTime()
        );

        if (label) {
            handleDateRangeFilter(label);
        } else {
            const startDate = value[0];
            const endDate = value[1];

            // startDate.setHours(0, 0, 0, 0);
            // endDate.setHours(23, 59, 59, 999);

            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : null;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : null;

            handleDateRangeFilter('custom', { startDate: formattedStartDate, endDate: formattedEndDate });
        }

        setSelectedRange(value);
    };


    const ranges = [
        { label: 'Today', value: [todayStart, todayEnd], placement: 'left' },
        { label: 'Yesterday', value: [yesterdayStart, yesterdayEnd], placement: 'left' },
        { label: 'Last 2 days', value: [lastTwoDaysStart, todayEnd], placement: 'left' },
        { label: 'Last 7 days', value: [last7DaysStart, todayEnd], placement: 'left' },
        { label: 'Last 30 days', value: [last30DaysStart, todayEnd], placement: 'left' },
        { label: 'This Month', value: [thisMonthStart, thisMonthEnd], placement: 'left' },
        { label: 'Last Month', value: [lastMonthStart, lastMonthEnd], placement: 'left' },
        { label: 'Last 6 Months', value: [last6MonthsStart, todayEnd], placement: 'left' },
        { label: 'This Year', value: [thisYearStart, thisYearEnd], placement: 'left' },
        { label: 'Last Year', value: [lastYearStart, lastYearEnd], placement: 'left' },
        // Add more predefined ranges here
    ];

    return (
        <div style={{ display: 'flex', justifyContent: 'justify-content-between' }}>
            <select
                value={selectedValue}
                onChange={handleSelect}
                className='form-control form-select mt-1'
                style={{ width: selectedValue === 'custom' ? '35%' : '100%' }}
            >
                <option value="">Select Date Range</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last_2_days">Last 2 days</option>
                <option value="last_7_days">Last 7 days</option>
                <option value="last_30_days">Last 30 days</option>
                <option value="this_month">This Month</option>
                <option value="last_month">Last Month</option>
                <option value="last_6_month">Last 6 Months</option>
                <option value="this_year">This Year</option>
                <option value="last_year">Last Year</option>
                <option value="custom">Custom</option>
            </select>

            {selectedValue === 'custom' && (
                <div className="mt-1 ms-1" style={{ minHeight: 38, width: '65%' }}>
                    <DateRangePicker
                        format="yyyy-MM-dd HH:mm:ss"
                        value={selectedRange}
                        onChange={handleDateRangeChange}
                        defaultOpen={true}
                        placement={placement}
                        ranges={[]}
                    />
                </div>
            )}
        </div>
    );

}

export default DateFilter;