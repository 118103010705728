// App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Header from './components/Layouts/Header';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './components/Errors/NotFound';
import { secure_routes } from './routes';
import Login from './components/Login';
import { getLocalStorageItem } from './helpers/helpers';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { ToastWithRouteTracking } from './ToastWithRouteTracking';

function App() {
    const user = getLocalStorageItem('user');
    return (
        <Router>
            <AuthProvider>
                <div>
                    {user && <Header />}

                    <Routes>
                        {/* Add a wildcard route for 404 */}
                        <Route path="/login" element={<Login />} />

                        {user ? (
                            <>
                                {secure_routes.map((route) => (
                                    <Route key={route.path} {...route} />
                                ))}
                            </>
                        ) : (
                            <Route
                                path="/*"
                                element={<Navigate to="/login" />}
                            />
                        )}

                        <Route path="/*" element={<NotFound />} />

                    </Routes>

                    {/* ... */}
                    <ToastWithRouteTracking />
                    {/* ... */}

                </div>
            </AuthProvider>
        </Router>
    );
}


export default App;
