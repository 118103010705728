// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { post } from './helpers/api';
import { getLocalStorageItem, removeLocalStorage, setLocalStorageItem } from './helpers/helpers';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(getLocalStorageItem('user') || null);

    const getInfo = async () => {
        try {
            const info = await post('/api/get_info', {});
            return info;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const login = async (credentials) => {
        try {
            const userData = await post('/api/authenticate', credentials);
            if (userData) {
                setUser(userData.data);
                setLocalStorageItem('user', userData.data.user);
                setLocalStorageItem('token', userData.data.token);
                return userData;
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            const token = getLocalStorageItem('token');
            if (token) {
                await post('/api/logout', null, true);
                setUser(null);
                removeLocalStorage('user');
                removeLocalStorage('token');
                removeLocalStorage('info'); // Remove info from local storage
                window.location.href = '/login';
            } else {
                console.error('Token not found in localStorage');
                setUser(null);
                removeLocalStorage('user');
                removeLocalStorage('token');
                removeLocalStorage('info'); // Remove info from local storage
                window.location.href = '/login';
            }
        } catch (error) {
            console.error('Logout error:', error.message);
            setUser(null);
            removeLocalStorage('user');
            removeLocalStorage('token');
            removeLocalStorage('info'); // Remove info from local storage
            window.location.href = '/login';
        }
    };
    
    return (
        <AuthContext.Provider value={{ user, login, logout, getInfo }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
