import React, { useState, useEffect, useRef } from 'react';
import { downloadReport, get } from '../../../helpers/api';
import { toast } from 'react-toastify';
import { getEventTypeName, getEventTypes, hasPermission, isMonitoringAgent, is_empty_object, setLocalStorageItem } from '../../../helpers/helpers';
import { FormField } from '../../../helpers/FormField';
import DateFilter from '../../Filters/DateFilter';
import ConfirmCustomerPin from '../../Customers/ConfirmCustomerPin';
import Loader from '../../Loader/Loader';
import $ from 'jquery';

const ReportsFilters = ({ report_type, onFilteredDataChange, onFilteredDataFetchedLoading, showEventTypeFilter = false, showDeviceFiter = true, showDateFilter = true, showModelFilter = false, showDurationFilter = false, duration_title = null }) => {
    const [tcDevices, setTCDevices] = useState([]);
    const [eventTypes, setEventTypes] = useState(getEventTypes());
    const [selectedEventTypes, setSelectedEventTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const [action, setAction] = useState('show');
    const [actionTitle, setActionTitle] = useState('Show');
    const [selectedCustomerID, setSelectedCustomerID] = useState(null);
    const defaultAskPinVerification = hasPermission('Ask Pin Verification');
    const [actionInProgress, setActionInProgress] = useState(false);
    const isMounted = useRef(true);
    const [modelNames, setModelNames] = useState([]);
    const [filters, setFilters] = useState({
        device_id: localStorage.getItem('reportSelectedDevice') ? JSON.parse(localStorage.getItem('reportSelectedDevice') ) : '',
        date_range: "today",
        start_date: null,
        end_date: null,
        modelName: ""
    });
    const [durationOperator, setDurationOperator] = useState('');
    const [durationValue, setDurationValue] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [exportLoading, setExportLoading] = useState(false);

    const handleDeviceFilterChange = (e) => {
        localStorage.setItem('reportSelectedDevice', JSON.stringify(e))
        if (e) {
            const customer_id = tcDevices.find(device => device.device_id === e.value)?.customer_id || null;
            setSelectedCustomerID(customer_id);
            setLocalStorageItem('device_id', e.value);
            setFilters((prevData) => ({
                ...prevData,
                'device_id': e,
            }));
        } else {
            setSelectedCustomerID(null);
            setLocalStorageItem('device_id', null);
            setFilters((prevData) => ({
                ...prevData,
                'device_id': null,
            }));
        }
    };    

    const handleModelFilterChange = (e) => {
        setFilters((prevData) => ({
            ...prevData,
            'modelName': e,
        }));
    }

    const handleDateFilterChange = (e, obj = null) => {
        if (e === 'custom') {
            setFilters((prevData) => ({
                ...prevData,
                'date_range': e,
                'start_date': obj?.startDate,
                'end_date': obj?.endDate,
            }));
        } else {
            setFilters((prevData) => ({
                ...prevData,
                'date_range': e,
                'start_date': null,
                'end_date': null,
            }));
        }
    }


    const handleEventTypes = (selectedTypes) => {
        setSelectedEventTypes(selectedTypes.map(type => type.value));
    };

    const handleOperatorChange = (e) => {
        setDurationOperator(e.target.value);
        if (e.target.value === "") {
            setDurationValue("");
        }
    };

    useEffect(() => {
        const fetchTCDevices = async () => {
            setLoading(true);
            try {
                const res = await get(`/api/devices`);
                if (Array.isArray(res.data)) {
                    setTCDevices(res.data);
                    const urlParams = new URLSearchParams(window.location.search);
                    const deviceIdFromUrl = parseInt(urlParams.get('device_id'));
                    if (deviceIdFromUrl) {
                        const selectedDevice = res.data.find(d => d.deviceid === deviceIdFromUrl);
                        if (selectedDevice) {
                            setFilters(prevFilters => ({
                                ...prevFilters,
                                device_id: { value: selectedDevice.deviceid, label: selectedDevice.name },
                            }));
                            handleDeviceFilterChange({ value: 'today' })
                            setTimeout(() => {
                                $('#performBtn').click();
                            }, 500);
                        }
                    }
                } else {
                    console.error('API response is not an array:', res.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            } finally {
                setLoading(false);
                onFilteredDataFetchedLoading(false);
            }
        };

        const fetchCompanyModelNames = async () => {
            try {
                setLoading(true);
                const response = await get(`/api/model-names/available`);
                setModelNames(response?.data);
            } catch (error) {
                console.error('Error fetching report data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isMounted.current && showDeviceFiter) fetchTCDevices();
        if (isMounted.current && showModelFilter) fetchCompanyModelNames();

        return () => {
            isMounted.current = false;
        };
    }, []);

    const validateFilterValues = (filters) => {
        let errors = {};
        if (showDeviceFiter && (!filters.device_id || filters.device_id === "")) {
            errors.device_id = "Select device";
        }
        if (filters.date_range === "" || filters.date_range === 'Select Date Range') {
            errors.date_range = "Select date filter";
        }
        if (filters.date_range === "custom" && filters.start_date === null && filters.end_date === null) {
            errors.date_range = "Select start and end date";
        }
        if ((!filters?.modelName || filters?.modelName?.value === undefined) && showModelFilter) {
            errors.modelName = "Select model";
        }

        console.log('model name value', filters.modelName?.value)
        setErrors(errors);
        return errors;
    };

    const getReportData = async (export_type = null) => {
        const errors = validateFilterValues(filters);
        console.log(errors, 'errors');
        if (!is_empty_object(errors)) {
            return;
        }
        setLoading(true); // Set loading state when fetching report data
    
        try {
            let url = `/api/reports/${report_type}?event_types=${selectedEventTypes.join(',')}&device_id=${filters?.device_id?.value ?? null}&date_range=${filters.date_range}`;
    
            if (filters.date_range === 'custom') {
                url += `&start_date=${filters.start_date}&end_date=${filters.end_date}`;
            }
    
            if (filters?.modelName?.value) {
                url += `&model_name=${filters?.modelName?.value}`;
            }
    
            if (durationOperator && durationValue) {
                url += `&duration_operator=${durationOperator}&duration_value=${durationValue}`;
            }
    
            if (export_type !== null) {
                url += `&export_report_type=${export_type}`;
                setExportLoading(true); // Set export loading state
            }
    
            if (export_type) {
                await downloadReport(url, `${export_type}`, `${export_type}`, `${filters.date_range}`, `${filters?.device_id?.value}`);
                setExportLoading(false); // Reset export loading state after download
            } else {
                const res = await get(url);
                if (res.code === 200 && export_type === null) {
                    onFilteredDataChange(res.data);
                }
                else if (res.code === 200 && export_type !== null) {
                    toast.success(`Your ${export_type} report is being processed.`);
                } else {
                    console.error('API response is not an array:', res.data);
                }
            }
    
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false); // Reset loading state after fetching report data
            if (export_type !== null) {
                setExportLoading(false); // Reset export loading state in case of error
            }
            onFilteredDataFetchedLoading(false);
        }
    };    

    const performAction = async (_action, _hasPinVerified) => {
        const errors = validateFilterValues(filters);
        if (!is_empty_object(errors)) {
            return;
        }
        if (_hasPinVerified) {
            // document.getElementById('ConfirmCustomerPinModalBtn').click();
            // return;
        }
        setLoading(true); // Set loading state when performing action
    
        try {
            await getReportData(_action === 'show' ? null : _action.toLowerCase());
        } finally {
            setLoading(false); // Reset loading state after performing action
        }
    };    

    const handleCustomerPinVerificationResponse = (isPinVerified = false) => {
        if (isPinVerified) {
            performAction(action, false);
        }
    }

    return (
        <div className="row">
            {/* <button ctype="button"
                className="btn btn-sm btn-secondary d-none"
                id='ConfirmCustomerPinModalBtn'
                data-bs-toggle="modal"
                data-bs-target="#ConfirmCustomerPinModal"
            ></button> */}
            {/* Note: We need work around pin verification if monitoring agent is accessing reports */}
            {/* <ConfirmCustomerPin selectedCustomerID={selectedCustomerID} pinVerified={(isPinVerified) => handleCustomerPinVerificationResponse(isPinVerified)} /> */}


            {showModelFilter &&
                <FormField
                    col={'col-xs-12 col-sm-12 col-md-12 col-lg-4'}
                    classes=""
                    label={'Model'}
                    type={'searchable-select'}
                    name={'modelName'}
                    onChange={(e) => handleModelFilterChange(e)}
                    value={filters?.modelName}
                    options={
                        modelNames.map((model_name, index) => ({
                            value: model_name,
                            label: model_name
                        }))
                    }
                    errorMessage={errors?.modelName}

                />
            }

            {showDeviceFiter && <FormField
                col={'col-xs-12 col-sm-12 col-md-12 col-lg-2 mt-1'}
                classes=""
                label={'Device'}
                type={'searchable-select'}
                name={'device_id'}
                required={true}
                value={filters.device_id}
                onChange={(e) => handleDeviceFilterChange(e)}
                options={tcDevices
                    .filter(device => device.device_id !== null)
                    .map(device => ({
                        value: device?.device_id,
                        label: `${device.number_plate} ${report_type === 'unusual-activity' ? `${device.idle_time}` : ''} `
                    }))
                }
                errorMessage={errors?.device_id}
            />}

            {showDateFilter &&
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="field">
                        <label className='form-label required'>Date Range</label>
                        <DateFilter handleDateRangeFilter={handleDateFilterChange} />
                        {errors.date_range && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.date_range}</div>}
                    </div>
                </div>}

            {showEventTypeFilter && (
                <FormField
                    classes={'basic-multi-select'}
                    col={'col-xs-12 col-sm-12 col-md-12 col-lg-3 mt-1'}
                    label={'Event Types'}
                    type={'multi-select'}
                    name={'event_types'}
                    value={selectedEventTypes.map((key) => ({
                        value: key,
                        label: getEventTypeName(key),
                    }))}
                    onChange={(e) => handleEventTypes(e)}
                    options={eventTypes.map((key) => ({
                        value: key,
                        label: getEventTypeName(key),
                    }))}
                />
            )}

            {showDurationFilter && (
                <>
                    <div className="col-lg-1 col-md-1 col-12 mt-1">
                        <div className="field">
                            <label className='form-label'>Operator</label>
                            <select className="form-control" value={durationOperator} onChange={handleOperatorChange}>
                                <option value="">CHOOSE</option>
                                <option value="greaterThan">&gt;</option>
                                <option value="lessThan">&lt;</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-12 mt-1">
                        <div className="field">
                            <label className='form-label'>{duration_title}</label>
                            <input type="number" className="form-control" value={durationValue} onChange={(e) => setDurationValue(e.target.value)} />
                        </div>
                    </div>
                </>
            )}

            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                <div className="field">
                    <label className='form-label'>&nbsp;</label>
                    <div className='form-group d-flex align-items-center'>
                        <div className="btn-group ms-2 ">
                            <button
                                type="button"
                                id='performBtn'
                                className="btn btn-outline-light w-200 text-dark border-secondary"
                                onClick={() => performAction(action, defaultAskPinVerification)}
                                disabled={actionInProgress}
                            >
                                {actionTitle}
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-light dropdown-toggle dropdown-toggle-split text-dark border-dark"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className="sr-only"></span>
                            </button>
                            <div className="dropdown-menu">
                                <button className="dropdown-item"
                                    onClick={() => {
                                        setAction('show');
                                        setActionTitle('Show');
                                        performAction('show', defaultAskPinVerification)
                                    }}>
                                    Show
                                </button>
                                <button className="dropdown-item"
                                    onClick={() => {
                                        setAction('html');
                                        setActionTitle('Save as HTML');
                                        performAction('html', defaultAskPinVerification)
                                    }}>
                                    Save as HTML
                                </button>
                                <button className="dropdown-item"
                                    onClick={() => {
                                        setAction('csv');
                                        setActionTitle('Save as CSV');
                                        performAction('csv', defaultAskPinVerification)
                                    }}>
                                    Save as CSV
                                </button>
                                <button className="dropdown-item"
                                    onClick={() => {
                                        setAction('pdf');
                                        setActionTitle('Save as PDF');
                                        performAction('pdf', defaultAskPinVerification)
                                    }}>
                                    Save as PDF
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}
export default ReportsFilters;
