import { get, put, post } from '../../helpers/api';  // Import the get function from your api helpers
import React, { useState, useEffect } from 'react';
import { FormField } from '../../helpers/FormField';
import { toast } from 'react-toastify';
import { is_empty_object } from '../../helpers/helpers';


function ModelNameForm({ show, onClose, modelNameID, fetchModelNames }) {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});

    // Fetch Company Model name data
    useEffect(() => {
        setFormData({});
        const fetchCompanyModelData = async () => {
            try {
                const res = await get(`/api/model-names/${modelNameID}`);
                setFormData({
                    model_name: res.data.model_name,
                    model_name_id: res.data.id,
                });
            } catch (error) {
                console.error('Error fetching model names:', error.message);
            }
        };
        if (show && modelNameID)
            fetchCompanyModelData();
        else if (show) {
            setFormData({});
            setErrors({});
        }
    }, [show, modelNameID]);

    // Hadle field change
    const handleChange = async (e) => {
        const { name, value } = e.target;

        setFormData(() => ({
            [name]: value,
            model_name_id: modelNameID
        }));
    }

    // Vaildate rules
    const validateValues = (formData) => {
        let errors = {};
        if (!formData.model_name || formData?.model_name === '') {
            errors.model_name = "Enter model name";
        }
        setErrors(errors);
        return errors;
    };

    // handle save/form submission
    const handleSave = async () => {
        const errors = validateValues(formData);
        if (!is_empty_object(errors)) {
            return;
        }

        try {
            var response = '';
            if (modelNameID)
                response = await put(`/api/model-names/update/${modelNameID}`, formData);
            else
                response = await post(`/api/model-names/store`, formData);

            toast.success(response.message);
            document.querySelector(`.btn-close-modal`).click();
            fetchModelNames();
        } catch (error) {
            toast.error(error.message);
        } finally {
        }
    };

    return (

        <div className="modal fade" id="modelNameModal" tabIndex="-1" aria-labelledby="modelNameModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modelNameModalLabel">
                            Model Name
                        </h5>
                        <button type="button" className="btn-close btn-close-modal" data-bs-dismiss="modal" aria-label="Close" onClick={() => { onClose() }}></button>
                    </div>
                    <div className="modal-body">
                        <form >
                            <div className='row'>
                                {/* Titile */}
                                <FormField
                                    col={'col-sm-12 col-md-12 col-lg-12'}
                                    label={'Name'}
                                    type={'text'}
                                    name={'model_name'}
                                    value={formData?.model_name || ''}
                                    onChange={handleChange}
                                    required={true}
                                    errorMessage={errors.model_name}
                                />

                                {/* Submit Button */}
                                <div className='col-12'>
                                    < div className="field mt-3">
                                        <button type="button" className="btn btn-sm btn-primary me-2" onClick={() => { handleSave() }}>
                                            {modelNameID ? 'Update' : 'Save'}
                                        </button>
                                        <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal" aria-label="Close" onClick={() => { onClose() }}> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form >
                    </div>
                </div>
            </div>
        </div>
    );
};



export default ModelNameForm;
