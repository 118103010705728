// src\ToastWithRouteTracking.js
import { ToastContainer } from 'react-toastify';
import { useRouteTracker } from './components/GoogleMaps/AgentMap/Sidebar/partials/PopupManage';

export const ToastWithRouteTracking = () => {
    useRouteTracker();

    return (
        <>
            {/* General Toast Notifications */}
            <ToastContainer />

            {/* Events Notifications */}
            <ToastContainer
                limit={10}
                style={{ padding: '0', marginTop: '42px', zIndex: 'auto' }}
                containerId="events-toast"
            />

            {/* Events Notifications */}
            <ToastContainer
                limit={1}
                containerId="commands-toast"
            />
        </>
    );
};
