import React from 'react';

function NoDataFoundTableRow({msg = 'No data found'}) {
    return (
        <tr className='bg-light text-center'>
            <td colSpan="100%" className='p-2'>{msg}</td>
        </tr>
    );
}

export default NoDataFoundTableRow;
