import React, { useState, useEffect } from 'react';
import { post } from '../../helpers/api';
import { toast } from 'react-toastify';
import { FormField } from '../../helpers/FormField';
import { is_empty_object } from '../../helpers/helpers';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ConfirmCustomerPin = ({ selectedDeviceID, selectedCustomerID, setSelectedCustomerID, setSelectedDeviceID, pinVerified }) => {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        pin_code: '',
        customer_id: ''
    });
    const [show, setShow] = useState(false);

    useEffect(() => {
        setFormData(() => ({
            customer_id: "",
            device_id: "",
            pin_code: "",
        }));
        return () => {
        };
    }, [selectedCustomerID]);

    useEffect(() => {
        if (selectedCustomerID) {
            setShow(true); // Open modal when selectedCustomerID changes
        }
    }, [selectedCustomerID]);

    const handleClose = () => {
        setShow(false);
        setSelectedCustomerID(null); // Reset selectedCustomerID when modal closes
        setSelectedDeviceID(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            customer_id: selectedCustomerID,
            device_id: selectedDeviceID,
            [name]: value,
        }));
    }

    const validateValues = (formData) => {
        let errors = {};
        if (!formData.pin_code) {
            errors.pin_code = "Enter pin code";
        }
        setErrors(errors);
        return errors;
    };

    const handleVerifyPin = async () => {
        const errors = validateValues(formData);
        if (!is_empty_object(errors)) {
            return;
        }
    
        try {
            const res = await post(`/api/customer/confirm-pin`, formData);
            if (res.status === 200) {
                pinVerified(true);
                localStorage.setItem(`isPinVerified_${selectedDeviceID}`, 'true'); // Save verification status with customer ID
                setTimeout(() => {
                    setFormData(() => ({
                        customer_id: "",
                        device_id: "",
                        pin_code: "",
                    }));
                    handleClose(); // Close modal on success
                }, 500);
            } else {
                pinVerified(false);
                toast.error(res.message || 'Invalid pin code');
            }
        } catch (error) {
            pinVerified(false);
            toast.error(error.message || 'An error occurred');
        } finally {
            // Reset selectedCustomerID only on closing of the modal or success
            if (!show) {
                setSelectedCustomerID(null);
                setSelectedDeviceID(null);
            }
        }
    };    
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleVerifyPin();
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{ padding: '0.8rem 0.8rem' }} closeButton>
                    <Modal.Title style={{ fontSize: '16px' }}>Confirm Pin Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="needs-validation" id='verifyCustomerPinForm' noValidate>
                        <div className="row g-3">

                            {/* Customer Pin Code */}
                            <FormField
                                col={'col-md-12'}
                                label={'Pin Code'}
                                type={'number'}
                                name={'pin_code'}
                                value={formData.pin_code || ''}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                required={true}
                                placeholder='Enter customer pin code'
                                errorMessage={errors.pin_code}
                            />
                        </div>

                        {/* Save Button */}
                        <div className='field'>
                            <Button variant="primary" size='sm' onClick={handleVerifyPin}>
                                Verify
                            </Button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConfirmCustomerPin;