import React from 'react';
import './Loader.css'; // Import the CSS file for styling

const Loader = ({ simple = true }) => {
    return (
        simple ? (
            <div className="loader-container">
                {/* <div className="loader"></div> */}
                <div className="spinner"></div>
            </div>
        ) : (
            <div className="loading">Loading...</div>
        )
    );
};

export default Loader;
